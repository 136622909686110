import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_Base_URL}`,
});

const config = (token: any) => {
  return {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };
};

export const generateStoriesAPI = (formData: any, token: string, activeSubProfileId: any) => {
  let url = "/stories/generate-story";

  if (activeSubProfileId !== null && activeSubProfileId !== undefined) {
    url += `?sec_acnt=${activeSubProfileId}`;
  }

  return api.post(url, formData, config(token));
};

export const getAllCollectionsAPI = (token: string, activeSubProfileId: any) => {
  let url = `/stories/collections`;

  if (activeSubProfileId !== null && activeSubProfileId !== undefined) {
    url += `?sec_acnt=${activeSubProfileId}`;
  }

  return api.get(url, config(token));
};

export const createCollectionAPI = (formData: any, token: string, activeSubProfileId: any) => {
  let url = `/stories/create-collection`;

  if (activeSubProfileId !== null && activeSubProfileId !== undefined) {
    url += `?sec_acnt=${activeSubProfileId}`;
  }

  return api.post(url, formData, config(token));
};

export const getAllStoriesAPI = (token: string, activeSubProfileId: any) => {
  let url = `/stories/all-stories`;

  if (activeSubProfileId !== null && activeSubProfileId !== undefined) {
    url += `?sec_acnt=${activeSubProfileId}`;
  }

  return api.get(url, config(token));
};

export const getStoriesInCollectionAPI = (id: any, token: string, activeSubProfileId: any) => {
  let url = `/stories/stories?coll_id=${id}`;

  if (activeSubProfileId !== null && activeSubProfileId !== undefined) {
    url += `&sec_acnt=${activeSubProfileId}`;
  }

  return api.get(url, config(token));
};

export const deleteCollectionAPI = (formData: any, token: string, activeSubProfileId: any) => {
  let url = `/stories/delete-collection`;

  if (activeSubProfileId !== null && activeSubProfileId !== undefined) {
    url += `?sec_acnt=${activeSubProfileId}`;
  }

  return api.post(url, formData, config(token));
};

export const deleteStoryAPI = (formData: any, token: string, activeSubProfileId: any) => {
  let url = `/stories/delete-story`;

  if (activeSubProfileId !== null && activeSubProfileId !== undefined) {
    url += `?sec_acnt=${activeSubProfileId}`;
  }

  return api.post(url, formData, config(token));
};

export const getStoryDetailsAPI = (id: any, token: string, activeSubProfileId: any) => {
  let url = `/stories/detail-story?story_id=${id}`;

  if (activeSubProfileId !== null && activeSubProfileId !== undefined) {
    url += `&sec_acnt=${activeSubProfileId}`;
  }

  return api.get(url, config(token));
};

export const moveStoriesToCollectionAPI = (formData: any, token: string, activeSubProfileId: any) => {
  let url = `/stories/move-stories`;

  if (activeSubProfileId !== null && activeSubProfileId !== undefined) {
    url += `?sec_acnt=${activeSubProfileId}`;
  }

  return api.post(url, formData, config(token));
};

export const saveStoryAPI = (formData: any, token: string, activeSubProfileId: any) => {
  let url = `/stories/save-story`;

  if (activeSubProfileId !== null && activeSubProfileId !== undefined) {
    url += `?sec_acnt=${activeSubProfileId}`;
  }

  return api.post(url, formData, config(token));
};

export const fetchCollectionAvatars = (token: string, activeSubProfileId: any) => {
  let url = `/stories/collection-avatars`;

  if (activeSubProfileId !== null && activeSubProfileId !== undefined) {
    url += `?sec_acnt=${activeSubProfileId}`;
  }

  return api.get(url, config(token));
};
