import { combineReducers } from "redux";
import loginSlice from "../slices/auth/loginSlice";
import signupSlice from "../slices/auth/signupSlice";
import verifyUserSlice from "../slices/auth/verifyUserSlice";
import sendCodeForForgotPasswordSlice from "../slices/auth/sendCodeForFogotPassword";
import changePassAfterEmailVerifySlice from "../slices/auth/changePassAfterEmailVerify";
import avatarsSlice from "store/slices/auth/avatarsSlice";
import accountInfoSlice from "store/slices/auth/accountInfoSlice";
import getProfileSlice from "store/slices/auth/getProfileSlice";
import secondaryProfilesSlice from "store/slices/auth/secondaryProfilesSlice";
import generateStoriesSlice from "store/slices/stories/generateStoriesSlice";
import getAllCollectionsSlice from "store/slices/stories/getAllCollectionsSlice";
import createCollectionSlice from "store/slices/stories/createCollectionSlice";
import allStoriesSlice from "store/slices/stories/allStoriesSlice";
import getStoriesInCollectionSlice from "store/slices/stories/getStoriesInCollectionSlice";
import deleteCollectionSlice from "store/slices/stories/deleteCollectionSlice";
import deleteStorySlice from "store/slices/stories/deleteStorySlice";
import combinedDataProductAndPlanSlice from "store/slices/stripe/combinedDataProductAndPlanSlice";
import userSubscriptionDetailsSlice from "store/slices/stripe/userSubscriptionDetailsSlice";
import checkoutSlice from "store/slices/stripe/checkoutSlice";
import getStoryDetailsSlice from "store/slices/stories/getStoryDetailsSlice";
import createSecondaryProfileSlice from "store/slices/auth/createSecondaryProfileSlice";
import activeProfilesSlice from "store/slices/auth/activeProfilesSlice";
import moveStoriesToCollectionSlice from "store/slices/stories/moveStoriesToCollectionSlice";
import saveStorySlice from "store/slices/stories/saveStorySlice";
import generateChapterSlice from "store/slices/stories/generateChapterSlice";
import collectionAvatarsSlice from "store/slices/stories/collectionAvatarsSlice";
import startFreeTrialSlice from "store/slices/auth/trials/startFreeTrialSlice";
import removeSecondaryAccountSlice from "store/slices/auth/removeSecondaryAccountSlice";
import changePasswordSlice from "store/slices/auth/changePasswordSlice";
import promptSlice from "store/slices/stories/promptSlice";

const rootReducer = combineReducers({
  // Accounts
  login: loginSlice,
  signup: signupSlice,
  verifyCode: verifyUserSlice,
  sendCodeForForgotPassword: sendCodeForForgotPasswordSlice,
  changePassAfterEmailVerify: changePassAfterEmailVerifySlice,
  avatar: avatarsSlice,
  accountInfo: accountInfoSlice,
  profile: getProfileSlice,
  secondaryProfiles: secondaryProfilesSlice,
  createSecondaryProfile: createSecondaryProfileSlice,
  activeProfiles: activeProfilesSlice,
  startFreeTrial: startFreeTrialSlice,
  removeSecondaryAccount:removeSecondaryAccountSlice,
  changePassword:changePasswordSlice,
  // stories
  prompt:promptSlice,
  generateStories: generateStoriesSlice,
  allCollections: getAllCollectionsSlice,
  createCollection: createCollectionSlice,
  allStories: allStoriesSlice,
  getStoriesInCollection: getStoriesInCollectionSlice,
  deleteCollection: deleteCollectionSlice,
  deleteStory: deleteStorySlice,
  storyDetails: getStoryDetailsSlice,
  moveStoriesToCollection: moveStoriesToCollectionSlice,
  saveStory: saveStorySlice,
  generateChapter: generateChapterSlice,
  collectionAvatars: collectionAvatarsSlice,
  // stripe
  combinedDataProductAndPlan: combinedDataProductAndPlanSlice,
  userSubscriptionDetails: userSubscriptionDetailsSlice,
  checkout: checkoutSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
