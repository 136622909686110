import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { resendCodeForForgetPassword } from "services/apis/auth";

export const sendCodeForForgotPassword = createAsyncThunk(
  "auth/sendCodeForForgotPassword",
  async (email: any, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("email", email);
      // for (const key in email) {
      //   formData.append(key, email[key]);
      // }
      const { data } = await resendCodeForForgetPassword(formData);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface sendCodeForForgotPasswordState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  code: any;
}

const initialState: sendCodeForForgotPasswordState = {
  status: "idle",
  error: null,
  code: null,
};
const sendCodeForForgotPasswordSlice = createSlice({
  name: "sendCodeForForgotPassword",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendCodeForForgotPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendCodeForForgotPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.code = action.payload;
      })
      .addCase(sendCodeForForgotPassword.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetStatus } = sendCodeForForgotPasswordSlice.actions;
export default sendCodeForForgotPasswordSlice.reducer;