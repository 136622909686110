import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createCollectionAPI } from "services/apis/stories";

export const createCollection = createAsyncThunk(
  "collections/create",
  async ({ collectionData, token, activeSubProfileId }: any, thunkAPI) => {
    try {
      const formData = new FormData();
      for (const key in collectionData) {
        formData.append(key, collectionData[key]);
      }
      const response = await createCollectionAPI(formData, token, activeSubProfileId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  newCollection: any;
}

const initialState: IState = {
  status: "idle",
  error: null,
  newCollection: null,
};

const createCollectionSlice = createSlice({
  name: "createCollection",
  initialState,
  reducers: {
    resetNewCollectionStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.newCollection = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCollection.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCollection.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.newCollection = action.payload;
      })
      .addCase(createCollection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetNewCollectionStatus } = createCollectionSlice.actions;
export default createCollectionSlice.reducer;
