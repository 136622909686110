import { createSlice } from "@reduxjs/toolkit";

const activeProfilesSlice = createSlice({
  name: "activeProfiles",
  initialState: {
    activeProfiles: [] as {
      id: number;
      email: string;
      username: string;
      isActive: boolean;
      profileType: "main" | "sub";
      image: string | null;
    }[],
  },
  reducers: {
    setProfiles: (state, action) => {
      const profilesToAddOrUpdate = action.payload;
      profilesToAddOrUpdate.forEach((profile: any) => {
        const existingProfileIndex = state.activeProfiles.findIndex(
          (p) => p.id === profile.id && p.profileType === profile.profileType
        );

        if (existingProfileIndex !== -1) {
          // If the profile exists
          if (profile.isActive) {
            // If incoming profile is active, update isActive for that profile
            state.activeProfiles[existingProfileIndex].isActive = true;
          }
          // Otherwise, do not update isActive for existing profile
        } else {
          // If the profile doesn't exist, add it
          state.activeProfiles.push({ ...profile });
        }
      });
    },
    setActiveProfile: (state, action) => {
      const profileToSetActive = action.payload;

      state.activeProfiles = state.activeProfiles.map((profile) =>
        profile.id === profileToSetActive.id && profile.profileType === profileToSetActive.profileType
          ? { ...profile, isActive: true }
          : { ...profile, isActive: false }
      );
    },
    updateProfileImage: (state, action) => {
      const { id, image, profileType } = action.payload;

      // Find the index of the profile with the given id
      const profileIndex = state.activeProfiles.findIndex((p) => p.id === id && p.profileType === profileType);

      // If the profile with the given id exists, update its image
      if (profileIndex !== -1) {
        state.activeProfiles[profileIndex].image = image;
      }
    },
    resetProfiles: (state) => {
      state.activeProfiles = [];
    },
  },
});

export const { setProfiles, setActiveProfile, updateProfileImage, resetProfiles } = activeProfilesSlice.actions;
export default activeProfilesSlice.reducer;
