import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generateStoriesAPI } from "services/apis/stories";

export const generateChapter = createAsyncThunk(
  "chapters/generate",
  async ({ prompt, token, story_id, activeSubProfileId }: any, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("prompt", prompt);
      if (story_id) {
        formData.append("story_id", story_id);
      }
      const response = await generateStoriesAPI(formData, token, activeSubProfileId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  generatedChapter: any;
}

const initialState: IState = {
  status: "idle",
  error: null,
  generatedChapter: null,
};

const generateChapterSlice = createSlice({
  name: "generateChapter",
  initialState,
  reducers: {
    resetGeneratedChapterStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateChapter.pending, (state) => {
        state.status = "loading";
      })
      .addCase(generateChapter.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.generatedChapter = action.payload;
      })
      .addCase(generateChapter.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetGeneratedChapterStatus } = generateChapterSlice.actions;
export default generateChapterSlice.reducer;
