import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteCollectionAPI } from "services/apis/stories";

export const deleteCollection = createAsyncThunk(
  "collections/delete",
  async ({ collectionId, token, activeSubProfileId }: any, thunkAPI) => {
    try {
      let formData = new FormData();
      formData.append("coll_id", collectionId);
      await deleteCollectionAPI(formData, token, activeSubProfileId);
      return collectionId;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
}

const initialState: IState = {
  status: "idle",
  error: null,
};

const deleteCollectionSlice = createSlice({
  name: "deleteCollection",
  initialState,
  reducers: {
    resetDeleteCollectionStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCollection.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCollection.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(deleteCollection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetDeleteCollectionStatus } = deleteCollectionSlice.actions;
export default deleteCollectionSlice.reducer;
