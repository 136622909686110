import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generateStoriesAPI } from "services/apis/stories";

export const generateStories = createAsyncThunk(
  "stories/generate",
  async ({ prompt, token, story_id, activeSubProfileId }: any, thunkAPI) => {
    try {
      const formData = new FormData();
      if (typeof prompt === "string") {
        formData.append("prompt", prompt);
      }
      if (typeof prompt === "object") {
        for (const key in prompt) {
          formData.append(key, prompt[key]);
        }
      }

      if (story_id) {
        formData.append("story_id", story_id);
      }
      const response = await generateStoriesAPI(formData, token, activeSubProfileId);
      // const response:any=null
      let isChapter = false;
      if (story_id && response.data) {
        isChapter = true;
      }
      return { data: response.data, isChapter };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  generatedStories: any;
  isChapter: boolean;
}

const initialState: IState = {
  status: "idle",
  error: null,
  generatedStories: [],
  isChapter: false,
};

const generateStoriesSlice = createSlice({
  name: "generateStories",
  initialState,
  reducers: {
    resetGeneratedStoriesStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.isChapter = false;
    },
    fullResetGeneratedStoriesStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.isChapter = false;
      state.generatedStories = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateStories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(generateStories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.generatedStories = action.payload.data;
        state.isChapter = action.payload.isChapter;
      })
      .addCase(generateStories.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetGeneratedStoriesStatus, fullResetGeneratedStoriesStatus } = generateStoriesSlice.actions;
export default generateStoriesSlice.reducer;
