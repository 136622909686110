import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_Base_URL}`,
});

const config = (token: any) => {
  return {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };
};

export const getCombinedDataProductAndPlan = () => api.get("/subscription/product-plans/");

export const getUserSubscriptionDetails = (token: string) => api.get("/subscription/user/", config(token));

export const checkout = (formData: any, token: string) => api.post("/subscription/checkout/", formData, config(token));
