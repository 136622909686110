import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProfileDetails } from "services/apis/auth";

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async ({ token, activeSubProfileId }: any, thunkAPI) => {
    try {
      const { data } = await getProfileDetails(token, activeSubProfileId);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface ProfileState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  profileDetails: any;
}

const initialState: ProfileState = {
  status: "idle",
  error: null,
  profileDetails: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfileStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.profileDetails = action.payload;
      })
      .addCase(getProfile.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetProfileStatus } = profileSlice.actions;
export default profileSlice.reducer;
