import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { changePassAfterEmailVerify } from "services/apis/auth";

export const changePassAfterEmailVerifyAction = createAsyncThunk(
  "auth/changePassAfterEmailVerify",
  async (content: any, thunkAPI) => {
    try {
      const formData = new FormData();
      for (const key in content) {
        formData.append(key, content[key]);
      }
      const { data } = await changePassAfterEmailVerify(formData);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface changePassAfterEmailVerifyState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  pass: any;
}

const initialState: changePassAfterEmailVerifyState = {
  status: "idle",
  error: null,
  pass: null,
};
const changePassAfterEmailVerifySlice = createSlice({
  name: "changePassAfterEmailVerify",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassAfterEmailVerifyAction.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changePassAfterEmailVerifyAction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pass = action.payload;
      })
      .addCase(
        changePassAfterEmailVerifyAction.rejected,
        (state, action: any) => {
          state.status = "failed";
          state.error = action.payload;
        }
      );
  },
});

export const { resetStatus } = changePassAfterEmailVerifySlice.actions;
export default changePassAfterEmailVerifySlice.reducer;