import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStoryDetailsAPI } from "services/apis/stories";

export const getStoryDetails = createAsyncThunk(
  "storyDetails/getDetails",
  async ({ storyId, token, activeSubProfileId }: any, thunkAPI) => {
    try {
      const { data } = await getStoryDetailsAPI(storyId, token, activeSubProfileId);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface StoryDetailsState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  storyDetails: any;
}

const initialState: StoryDetailsState = {
  status: "idle",
  error: null,
  storyDetails: null,
};

const storyDetailsSlice = createSlice({
  name: "storyDetails",
  initialState,
  reducers: {
    resetStoryDetailsStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoryDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStoryDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.storyDetails = action.payload;
      })
      .addCase(getStoryDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetStoryDetailsStatus } = storyDetailsSlice.actions;
export default storyDetailsSlice.reducer;
