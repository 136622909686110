import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { accountInfo } from "services/apis/auth";

export const AccountInfo = createAsyncThunk(
  "auth/account-info",
  async ({ profileDetails, token, activeSubProfileId }: any, thunkAPI) => {
    try {
      const formData = new FormData();

      for (const key in profileDetails) {
        if (profileDetails.hasOwnProperty(key)) {
          formData.append(key, profileDetails[key]);
        }
      }
      const { data } = await accountInfo(formData, token, activeSubProfileId);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  accountInfo: any;
}

const initialState: IState = {
  status: "idle",
  error: null,
  accountInfo: null,
};

const accountInfoSlice = createSlice({
  name: "account-info",
  initialState,
  reducers: {
    resetAccountInfoStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AccountInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(AccountInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.accountInfo = action.payload;
      })
      .addCase(AccountInfo.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetAccountInfoStatus } = accountInfoSlice.actions;
export default accountInfoSlice.reducer;
