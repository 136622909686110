import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { startFreeTrialAPI } from "services/apis/auth";

export const startFreeTrial = createAsyncThunk("startFreeTrial/start", async (token: any, thunkAPI) => {
  try {
    const response = await startFreeTrialAPI(token);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface IState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  trialData: any;
}

const initialState: IState = {
  status: "idle",
  error: null,
  trialData: null,
};

const startFreeTrialSlice = createSlice({
  name: "startFreeTrial",
  initialState,
  reducers: {
    resetTrialStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startFreeTrial.pending, (state) => {
        state.status = "loading";
      })
      .addCase(startFreeTrial.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.trialData = action.payload;
      })
      .addCase(startFreeTrial.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetTrialStatus } = startFreeTrialSlice.actions;
export default startFreeTrialSlice.reducer;
