import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCombinedDataProductAndPlan } from "services/apis/stripe";
export const combinedDataProductAndPlanAction = createAsyncThunk(
  "pricing/combinedDataProductAndPlan",
  async (_: any, thunkAPI) => {
    try {
      const { data } = await getCombinedDataProductAndPlan();
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
interface IState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  combinedData: any;
}

const initialState: IState = {
  status: "idle",
  error: null,
  combinedData: null,
};
const combinedDataProductAndPlanSlice = createSlice({
  name: "combinedDataProductAndPlan",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(combinedDataProductAndPlanAction.pending, (state) => {
        state.status = "loading";
      })
      .addCase(combinedDataProductAndPlanAction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.combinedData = action.payload;
      })
      .addCase(combinedDataProductAndPlanAction.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});
export const { resetStatus } = combinedDataProductAndPlanSlice.actions;
export default combinedDataProductAndPlanSlice.reducer;
