import { createSlice } from "@reduxjs/toolkit";

const promptSlice = createSlice({
  name: "prompt",
  initialState: {
    value: "",
  },
  reducers: {
    setPromptValue: (state, action) => {
      state.value = action.payload;
    },
    resetPromptValue: (state) => {
      state.value = "";
    },
  },
});

export const { setPromptValue, resetPromptValue } = promptSlice.actions;
export default promptSlice.reducer;
