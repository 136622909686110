import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllCollectionsAPI } from "services/apis/stories";

export const getAllCollections = createAsyncThunk(
  "collections/getAll",
  async ({ token, activeSubProfileId }: any, thunkAPI) => {
    try {
      const response = await getAllCollectionsAPI(token, activeSubProfileId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  collections: any;
}

const initialState: IState = {
  status: "idle",
  error: null,
  collections: [],
};

const collectionsSlice = createSlice({
  name: "getAllCollections",
  initialState,
  reducers: {
    resetCollectionsStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCollections.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllCollections.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.collections = action.payload;
      })
      .addCase(getAllCollections.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetCollectionsStatus } = collectionsSlice.actions;
export default collectionsSlice.reducer;
