import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer/rootReducer";
import createFilter from "redux-persist-transform-filter";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
const devTools =
  process.env.NODE_ENV === "development"
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    : undefined;

const loginFilter = createFilter("login", ["isLoggedIn", "user"]);
const signupFilter = createFilter("signup", ["user.detail.email", "user.detail.id", "date"]);
// const ctxFilter = createFilter("ctx", ["ctx"]);
// const selectedAccountFiler = createFilter("selectedAccount", ["account"]);
// const generateStoriesFilter = createFilter("generateStories", ["generatedStories"]);
const persistConfig = {
  key: "sparklet.ai",
  storage,
  whitelist: ["login", "signup" /* "generateStories"*/, "activeProfiles"],
  transforms: [loginFilter, signupFilter /*ctxFilter, selectedAccountFiler,, generateStoriesFilter*/],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);
export { store, persistor };
export type AppDispatch = typeof store.dispatch;
