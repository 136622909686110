import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAvatar } from "services/apis/auth";

export const Avatar = createAsyncThunk("avatar/Avatar", async (token: string, thunkAPI) => {
  try {
    const { data } = await getAvatar(token);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface avatarState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  avatarData: any;
}

const initialState: avatarState = {
  status: "idle",
  error: null,
  avatarData: null,
};

const avatarSlice = createSlice({
  name: "avatar",
  initialState,
  reducers: {
    resetAvatarStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(Avatar.pending, (state) => {
        state.status = "loading";
      })
      .addCase(Avatar.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.avatarData = action.payload;
      })
      .addCase(Avatar.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetAvatarStatus } = avatarSlice.actions;
export default avatarSlice.reducer;
