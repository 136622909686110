import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCollectionAvatars } from "services/apis/stories";

export const fetchCollectionAvatarsData = createAsyncThunk(
  "collectionAvatars/fetch",
  async ({ token, activeSubProfileId }: any, thunkAPI) => {
    try {
      const response = await fetchCollectionAvatars(token, activeSubProfileId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  avatars: any;
}

const initialState: IState = {
  status: "idle",
  error: null,
  avatars: [],
};

const collectionAvatarsSlice = createSlice({
  name: "getCollectionAvatars",
  initialState,
  reducers: {
    resetCollectionAvatarsStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollectionAvatarsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCollectionAvatarsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.avatars = action.payload;
      })
      .addCase(fetchCollectionAvatarsData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetCollectionAvatarsStatus } = collectionAvatarsSlice.actions;
export default collectionAvatarsSlice.reducer;
