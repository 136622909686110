import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteStoryAPI } from "services/apis/stories";

export const deleteStory = createAsyncThunk(
  "stories/delete",
  async ({ storyId, token, activeSubProfileId }: any, thunkAPI) => {
    try {
      let formData = new FormData();
      formData.append("story_id", storyId);
      await deleteStoryAPI(formData, token, activeSubProfileId);
      return storyId;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
}

const initialState: IState = {
  status: "idle",
  error: null,
};

const deleteStorySlice = createSlice({
  name: "deleteStory",
  initialState,
  reducers: {
    resetDeleteStoryStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteStory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteStory.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(deleteStory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetDeleteStoryStatus } = deleteStorySlice.actions;
export default deleteStorySlice.reducer;
