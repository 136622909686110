import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PreLoader from "components/preLoader/PreLoader";
import { useAppSelector } from "services/hooks/hooks";
import { RequireAuth } from "services/utils/RequireAuth";
const Login = lazy(() => import("../pages/Login"));
const Signup = lazy(() => import("../pages/Signup"));
// const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
// const VerifyEmail = lazy(() => import("../pages/VerifyEmail"));
const Profile = lazy(() => import("../pages/Profile"));
const SecondaryProfile = lazy(() => import("../pages/SecondaryProfile"));
const StoryTab = lazy(() => import("../pages/StoryTab"));
const StoryTabIdeas = lazy(() => import("../pages/StoryTabIdeas"));
const GenerateStory = lazy(() => import("../pages/GenerateStory"));
const LibraryTab = lazy(() => import("../pages/LibraryTab"));
const MarketplaceTab = lazy(() => import("../pages/MarketplaceTab"));
const MembershipTab = lazy(() => import("../pages/MembershipTab"));
const UpgradeMembership = lazy(() => import("../pages/UpgradeMembership"));
const FAQ = lazy(() => import("pages/FAQ"));
const ProfileInfo = lazy(() => import("../pages/ProfileInfo"));
const ManageAccount = lazy(() => import("../pages/ManageAccount"));

const Config = () => {
  const loginReducer = useAppSelector((state) => ({
    isLoggedIn: state.login.isLoggedIn,
  }));
  return (
    <Router>
      <Suspense fallback={<PreLoader />}>
        <Routes>
          {/* Redirected Route */}
          <Route
            path="/"
            element={<Navigate to={`/story`} replace state={"/"} />}
          ></Route>

          {/*<------------ Authentication Routes ----------------->*/}

          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          {/* <Route path="/forgot-password" element={<ForgotPassword />}></Route> */}
          {/* <Route path="/verify-email" element={<VerifyEmail />}></Route> */}

          {/* <------Protected Routes------> */}
          <Route
            path="/profile"
            element={
              <RequireAuth
                redirectTo="/login"
                isLoggedIn={loginReducer?.isLoggedIn}
              >
                <Profile />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/secondary-profile"
            element={
              <RequireAuth
                redirectTo="/login"
                isLoggedIn={loginReducer?.isLoggedIn}
              >
                <SecondaryProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/profile-info"
            element={
              <RequireAuth
                redirectTo="/login"
                isLoggedIn={loginReducer?.isLoggedIn}
              >
                <ProfileInfo />
              </RequireAuth>
            }
          />

          {/* <Route
            path="/story"
            element={
              <RequireAuth redirectTo="/login" isLoggedIn={loginReducer?.isLoggedIn}>
                <StoryTab />
              </RequireAuth>
            }
          /> */}

          <Route path="/story" element={<StoryTab />} />

          <Route
            path="/story-ideas"
            element={
              <RequireAuth
                redirectTo="/login"
                isLoggedIn={loginReducer?.isLoggedIn}
              >
                <StoryTabIdeas />
              </RequireAuth>
            }
          />
          <Route
            path="/generate-story/:col_id?/:story_id?"
            element={
              <RequireAuth
                redirectTo="/login"
                isLoggedIn={loginReducer?.isLoggedIn}
              >
                <GenerateStory />
              </RequireAuth>
            }
          />
          <Route
            path="/library/:id?"
            element={
              <RequireAuth
                redirectTo="/login"
                isLoggedIn={loginReducer?.isLoggedIn}
              >
                <LibraryTab />
              </RequireAuth>
            }
          />

          <Route
            path="/marketplace"
            element={
              <RequireAuth
                redirectTo="/login"
                isLoggedIn={loginReducer?.isLoggedIn}
              >
                <MarketplaceTab />
              </RequireAuth>
            }
          />

          <Route
            path="/membership"
            element={
              <RequireAuth
                redirectTo="/login"
                isLoggedIn={loginReducer?.isLoggedIn}
              >
                <MembershipTab />
              </RequireAuth>
            }
          />

          <Route
            path="/upgrade-membership"
            element={
              <RequireAuth
                redirectTo="/login"
                isLoggedIn={loginReducer?.isLoggedIn}
              >
                <UpgradeMembership />
              </RequireAuth>
            }
          />

          <Route
            path="/support"
            element={
              <RequireAuth
                redirectTo="/login"
                isLoggedIn={loginReducer?.isLoggedIn}
              >
                <FAQ />
              </RequireAuth>
            }
          />

          <Route
            path="/manage-account"
            element={
              <RequireAuth
                redirectTo="/login"
                isLoggedIn={loginReducer?.isLoggedIn}
              >
                <ManageAccount />
              </RequireAuth>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Config;
