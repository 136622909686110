import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchSecondaryProfiles } from "services/apis/auth";

export const fetchSecondaryProfilesData = createAsyncThunk("profiles/fetch-secondary", async (token: any, thunkAPI) => {
  try {
    const response = await fetchSecondaryProfiles(token);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface IState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  secondaryProfiles: any[];
}

const initialState: IState = {
  status: "idle",
  error: null,
  secondaryProfiles: [],
};

const secondaryProfilesSlice = createSlice({
  name: "secondary-profiles",
  initialState,
  reducers: {
    resetSecondaryProfilesStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSecondaryProfilesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSecondaryProfilesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.secondaryProfiles = action.payload;
      })
      .addCase(fetchSecondaryProfilesData.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetSecondaryProfilesStatus } = secondaryProfilesSlice.actions;
export default secondaryProfilesSlice.reducer;
