import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStoriesInCollectionAPI } from "services/apis/stories";

export const getStoriesInCollection = createAsyncThunk(
  "collectionStories/get",
  async ({ collectionId, token, activeSubProfileId }: any, thunkAPI) => {
    try {
      const response = await getStoriesInCollectionAPI(collectionId, token, activeSubProfileId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  collectionStories: any;
}

const initialState: IState = {
  status: "idle",
  error: null,
  collectionStories: [],
};

const getStoriesInCollectionSlice = createSlice({
  name: "collectionStories",
  initialState,
  reducers: {
    resetCollectionStoriesStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoriesInCollection.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStoriesInCollection.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.collectionStories = action.payload;
      })
      .addCase(getStoriesInCollection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetCollectionStoriesStatus } = getStoriesInCollectionSlice.actions;
export default getStoriesInCollectionSlice.reducer;
