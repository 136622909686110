import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { checkout } from "services/apis/stripe";
export const checkoutAction = createAsyncThunk("pricing/checkout", async ({ planId, token, type }: any, thunkAPI) => {
  // const { payload, files, type, contact } = adData;
  try {
    // const payload1: any = {
    //   payload: JSON.stringify({ fields: payload }),
    //   type: type,
    //   contact: JSON.stringify(contact),
    // };
    const formData = new FormData();
    // for (let i = 0; i < files.length; i++) {
    //   formData.append(`files`, files[i]);
    // }
    // for (const key in payload1) {
    //   formData.append(key, payload1[key]);
    // }
    formData.append("plan_id", planId);
    formData.append("type", type);

    const { data } = await checkout(formData, token);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data?.message);
  }
});
interface IState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  checkout: any;
}

const initialState: IState = {
  status: "idle",
  error: null,
  checkout: null,
};
const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkoutAction.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkoutAction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.checkout = action.payload;
      })
      .addCase(checkoutAction.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});
export const { resetStatus } = checkoutSlice.actions;
export default checkoutSlice.reducer;
