import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_Base_URL}`,
});

const config = (token: any) => {
  return {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };
};

export const signup = (formData: any) => api.post("/accounts/register/", formData);

export const login = (formData: any) => api.post("/accounts/login/", formData);

export const verifyCode = (formData: any) => api.post("/accounts/verify-code/", formData);

export const resendCodeForEmailVerification = (formData: any) =>
  api.post("/accounts/send-verification-email/", formData);

export const resendCodeForForgetPassword = (formData: any) =>
  api.post("/accounts/send-verification-email-password/", formData);

export const changePassAfterEmailVerify = (formData: any) => api.post("/accounts/change-password/", formData);

export const getAvatar = (token: string) => api.get("/accounts/avatars", config(token));

export const accountInfo = (formData: any, token: string, activeSubProfileId: any) => {
  let url = "/accounts/account-info";
  if (activeSubProfileId !== null && activeSubProfileId !== undefined) {
    url += `?sec_acnt=${activeSubProfileId}`;
  }

  return api.post(url, formData, config(token));
};

export const getProfileDetails = (token: string, activeSubProfileId: any) => {
  let url = "/accounts/user-account-info";
  if (activeSubProfileId !== null && activeSubProfileId !== undefined) {
    url += `?sec_acnt=${activeSubProfileId}`;
  }

  return api.get(url, config(token));
};

export const fetchSecondaryProfiles = (token: string) => api.get("/accounts/secondary-profiles/", config(token));

export const createSecondaryProfileAPI = (token: string, formdata: any) =>
  api.post("/accounts/create-secondary-account/", formdata, config(token));

export const startFreeTrialAPI = (token: string) => api.get("/accounts/start-free-trail", config(token));

export const removeSecondaryAccountAPI = (token: string, sec_annt: number) =>
  api.get(`/accounts/remove-secondary-account?sec_annt=${sec_annt}`, config(token));

export const changePasswordAPI = (token: string, formData: any) =>
  api.post(`/accounts/change-password/`, formData, config(token));
