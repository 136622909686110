import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createSecondaryProfileAPI } from "services/apis/auth";

export const createSecondaryProfileAction = createAsyncThunk(
  "secondaryProfile/create",
  async ({ profileData, token }: any, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("display_name", profileData.display_name);
      const response = await createSecondaryProfileAPI(token, formData);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface SecondaryProfileState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  createdProfile: any; // Update the type as per your profile structure
}

const initialState: SecondaryProfileState = {
  status: "idle",
  error: null,
  createdProfile: null,
};

const createSecondaryProfileSlice = createSlice({
  name: "createSecondaryProfile",
  initialState,
  reducers: {
    resetCreateSecondaryProfileStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSecondaryProfileAction.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSecondaryProfileAction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.createdProfile = action.payload;
      })
      .addCase(createSecondaryProfileAction.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetCreateSecondaryProfileStatus } = createSecondaryProfileSlice.actions;
export default createSecondaryProfileSlice.reducer;
