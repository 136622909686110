import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { removeSecondaryAccountAPI } from "services/apis/auth";

export const removeSecondaryAccount = createAsyncThunk(
  "account/removeSecondaryAccount",
  async ({ token, sec_annt }: any, thunkAPI) => {
    try {
      const { data } = await removeSecondaryAccountAPI(token, sec_annt);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface AccountState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
}

const initialState: AccountState = {
  status: "idle",
  error: null,
};

const removeSecondaryAccountSlice = createSlice({
  name: "removeSecondaryAccount",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeSecondaryAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeSecondaryAccount.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(removeSecondaryAccount.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetStatus } = removeSecondaryAccountSlice.actions;
export default removeSecondaryAccountSlice.reducer;
